import React, { FC } from 'react';
import router from 'routes';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

const App: FC = () => {
  return (
    /*<SentryProvider>*/
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
    /*</SentryProvider>*/
  );
};

export default App;

import React, { FC, useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, useNavigation } from 'react-router-dom';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import Form from '@modules/home/Form';

const NavigationLoader: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  return <Outlet />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationLoader />}>
      <Route path="/" element={<Form />} />
      <Route path="*" element={<ErrorPage />} />
    </Route>,
  ),
);

export default router;

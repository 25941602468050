import React, { ChangeEventHandler, FC, useCallback, useState } from 'react';
import { DBSchema, IDBPDatabase, openDB } from 'idb';
import nookies, { parseCookies } from 'nookies';
import { Box, Button, Input, Text } from '@mantine/core';
import { renderHttpRemoteData } from '@shared/utils/render';
import { useFetchTask } from '@core/http/hooks';
import * as TE from 'fp-ts/TaskEither';
import { HttpError } from '@core/http';

interface MyDB extends DBSchema {
  name: {
    key: string;
    value: string;
  };
}

async function getDB(): Promise<IDBPDatabase<MyDB>> {
  return await openDB<MyDB>('my-db', 1, {
    upgrade(db) {
      db.createObjectStore('name');
    },
  });
}

async function setIndexedDBValue(db: Promise<IDBPDatabase<MyDB>>, value: string) {
  return (await db).put('name', value, 'name');
}

async function getIndexedDBValue(db: Promise<IDBPDatabase<MyDB>>) {
  return (await db).get('name', 'name');
}

async function setCookieValue(value: string) {
  return nookies.set(null, 'name', value);
}

const db = getDB();

const Form: FC = () => {
  const [name, setName] = useState<string | null>(null);

  const task = useCallback(() => {
    return TE.tryCatch(
      () => getIndexedDBValue(db),
      () => HttpError.notFound,
    );
  }, []);

  const [indexedDBVal, refreshIndexedDBVal] = useFetchTask(task);

  const cookiesVal = parseCookies();
  const localStorageVal = localStorage.getItem('name');

  const handleSubmit = () => {
    setIndexedDBValue(db, name ?? '');
    setCookieValue(name ?? '');
    localStorage.setItem('name', name ?? '');
    setName(null);

    refreshIndexedDBVal();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;

    setName(value);
  };

  return (
    <Box m="md">
      <Input name="name" value={name ?? ''} onChange={handleChange} />

      <Button onClick={handleSubmit} my="md">
        Enregistrer
      </Button>

      <Text>Cookies : {cookiesVal.name}</Text>

      {renderHttpRemoteData(indexedDBVal, val => (
        <Text>IndexedDB : {val}</Text>
      ))}

      <Text>Local storage : {localStorageVal}</Text>
    </Box>
  );
};

export default Form;
